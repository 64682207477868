












































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { LocaleMessages } from 'vue-i18n';
import countries from 'i18n-iso-countries/langs/en.json';

const Datepicker = () => import('vuejs-datepicker');
const Multiselect = () => import('vue-multiselect');
const VuePhoneNumberInput = () => import('vue-phone-number-input');
const IconCheck = () => import('../assets/fa-icons/regular/check.svg');

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface SelectOption {
  id?: string | null;
  label: string | LocaleMessages | null;
  group?: SelectOption[] | null;
}

@Component({
  components: {
    IconCheck,
    'v-datepicker': Datepicker,
    'v-select': Multiselect,
    'v-phone-input': VuePhoneNumberInput,
  },
})
export default class FormElement extends Vue {
  @Prop([String, Number, Boolean, Object, Date, Array])
  value!: string | number | boolean | Record<string, any> | Date | SelectOption | any[];

  @Prop([String, Number, Boolean, Object, Date, Array])
  emptyValue!: string | number | boolean | Record<string, any> | Date | [];

  @Prop(String)
  fieldValue!: string;

  @Prop(Boolean)
  required!: boolean;

  @Prop({ type: Number, default: 1 })
  maxFiles!: number;

  @Prop(Number)
  minWordLength!: number;

  @Prop(Number)
  maxWordLength!: number;

  @Prop(String)
  fieldName!: string;

  @Prop({ type: String, default: 'text' })
  fieldType!: 'text' | 'textarea' | 'number' | 'email' | 'select' | 'country' | 'phone' | 'boolean'|'checkbox' | 'radio' | 'date' | 'multiselect' | 'multi-select' | 'file';

  @Prop(Array)
  fieldOptions!: SelectOption[];

  @Prop(Boolean)
  fieldDisabled!: boolean;

  @Prop({ type: String, default: 'year' })
  initialView!: string;

  @Prop({
    type: Object,
    default () {
      return { dates: [new Date()] };
    },
  })
  highlightedDates!: Record<string, any>

  @Prop(String)
  fieldHint!: string;

  @Prop(String)
  selectorPath!: string;

  @Prop({ type: String, default: 'single-line' })
  formStyle!: 'single-line' | 'multi-line' | 'inline' | string;

  validated = false;
  valid = !this.required;
  errorMessage: string | null = null;
  elementValue = this.getEmptyValue;
  componentSpecificOptions = {};

  selectLabel ({ label }: SelectOption): string | LocaleMessages | null {
    return label;
  }

  validate (newValue: any): void {
    if (this.fieldDisabled) {
      return;
    }

    this.errorMessage = null;
    this.valid = !!newValue;
    if (['text', 'textarea'].includes(this.fieldType)) {
      this.validateText(newValue);
    } else if (this.fieldType === 'number') {
      this.validateNumber(newValue);
    } else if (this.fieldType === 'email') {
      this.validateEmail(newValue);
    } else if (['select', 'country'].includes(this.fieldType)) {
      this.validateSelect(newValue);
    } else if (this.fieldType === 'phone') {
      this.validatePhone(newValue);
    } else if (this.isBooleanType) {
      this.validateBoolean(newValue);
    } else if (this.fieldType === 'date') {
      this.validateDate(newValue);
    } else if (['multiselect', 'multi-select'].includes(this.fieldType)) {
      this.validateMultiSelect(newValue);
    } else if (this.fieldType === 'file') {
      this.validateFile(newValue);
    }
    this.validated = true;

    this.$emit('change', newValue);
    this.$emit('valid', this.valid);

    this.elementValue = newValue;
  }

  validateText (newValue: string): void {
    if ((this.minWordLength) || (this.maxWordLength)) {
      const wordCount = newValue.split(' ').length;
      const satisfiesMin = this.minWordLength ? wordCount > this.minWordLength : true;
      const satisfiesMax = this.maxWordLength ? wordCount < this.maxWordLength : true;
      if (!satisfiesMin) {
        this.errorMessage = 'formElement.error.notEnoughWords';
      }
      if (!satisfiesMax) {
        this.errorMessage = 'formElement.error.tooManyWords';
      }

      this.valid = satisfiesMin && satisfiesMax;
    }

    this.valid = this.required
      ? !!newValue && newValue.length >= 2
      : true;
  }

  validateNumber (newValue: number | null): void {
    this.valid = this.required || newValue
      ? typeof newValue === 'number'
      : true;
  }

  validateEmail (newValue: string): void {
    this.valid = this.required || newValue
      ? EMAIL_REGEX.test(newValue)
      : true;
  }

  validateSelect (newValue: any): void {
    this.valid = this.required
      ? (newValue && newValue.id != null)
      : true;
  }

  validateMultiSelect (newValue: []): void {
    this.valid = this.required
      ? newValue.length > 0
      : true;
  }

  validatePhone (newValue: any): void {
    this.valid = this.required || newValue
      ? !!newValue
      : true;
  }

  validateBoolean (newValue: boolean | null): void {
    this.valid = this.required
      ? newValue === true
      : true;
  }

  validateDate (newValue: string): void {
    const date = new Date(newValue);
    this.valid = this.required || newValue
      ? !Number.isNaN(date.getTime())
      : true;
  }

  validateFile (newValue: any): void {
    // eslint-disable-next-line no-console
    console.log({ newValue });
    this.valid = true;
  }

  get isInputType (): boolean {
    const inputCases = ['text', 'email'];
    return inputCases.indexOf(this.fieldType) >= 0;
  }

  get isNumberType (): boolean {
    return this.fieldType === 'number';
  }

  get isBooleanType (): boolean {
    return ['boolean', 'checkbox'].includes(this.fieldType);
  }

  get isRadioType (): boolean {
    return this.fieldType === 'radio';
  }

  get isTextareaType (): boolean {
    return this.fieldType === 'textarea';
  }

  get isSelectType (): boolean {
    return this.fieldType === 'select';
  }

  get isMultiSelectType (): boolean {
    return this.fieldType === 'multi-select' || this.fieldType === 'multiselect';
  }

  get isCountryType (): boolean {
    return this.fieldType === 'country';
  }

  get isDateType (): boolean {
    return this.fieldType === 'date';
  }

  get isPhoneType (): boolean {
    return this.fieldType === 'phone';
  }

  get isFileType (): boolean {
    return this.fieldType === 'file';
  }

  get getEmptyValue (): any {
    if (this.emptyValue) {
      return this.emptyValue;
    }

    switch (this.fieldType) {
      case 'text':
      case 'textarea':
      case 'email':
      case 'phone':
        return '';
      case 'number':
        return 0;
      case 'select':
      case 'country':
      case 'date':
        return null;
      case 'boolean':
      case 'checkbox':
        return false;
      case 'multiselect':
      case 'multi-select':
        return [];
      default:
        return null;
    }
  }

  get getErrorMessage (): string | null {
    if (this.valid) {
      return null;
    }

    return this.errorMessage || `formElement.error.${this.fieldType}`;
  }

  get joinedElementValues (): string | null {
    return this.isMultiSelectType && this.elementValue
      ? this.elementValue.map((value: SelectOption) => value.id).join(',')
      : null;
  }

  get checkedClass (): Record<string, boolean> {
    return {
      'common-form-element__boolean-input--checked': this.elementValue === true,
    };
  }

  get rootFormClass (): Record<string, boolean> {
    return {
      'common-form-element--single-row': this.formStyle === 'single-row',
      'common-form-element--multi-row': this.formStyle === 'multi-row',
      'common-form-element--inline': this.formStyle === 'inline',
      'common-form-element--valid': this.validated && this.valid,
      'common-form-element--invalid': this.validated && !this.valid,
      'common-form-element--boolean': this.isBooleanType || this.isRadioType,
      'common-form-element--disabled': this.fieldDisabled,
    };
  }

  get labelClass (): Record<string, boolean> {
    return {
      'common-form-element__label--required': this.required && !this.isRadioType,
    };
  }

  get getFieldValue (): string {
    if (this.fieldValue) {
      return this.fieldValue;
    }

    if (this.isBooleanType) {
      return 'true';
    }

    return '';
  }

  get fieldId (): string {
    if (this.isRadioType) {
      return `${this.fieldName}-${this.getFieldValue}-id`;
    }

    return this.fieldName;
  }

  get selectHasGroup (): boolean {
    return !!this.selectOptions?.find((option) => option.group);
  }

  get groupLabel (): string | undefined {
    if (this.selectOptions) {
      return this.selectHasGroup ? 'label' : undefined;
    }

    return undefined;
  }

  get groupValue (): string | undefined {
    if (this.selectOptions) {
      return this.selectHasGroup ? 'group' : undefined;
    }

    return undefined;
  }

  get multiselectClasses (): Record<string, boolean> {
    return {
      'multiselect--groups': this.selectHasGroup,
    };
  }

  get selectOptions (): SelectOption[] | null {
    if (this.isCountryType) {
      const selectList: SelectOption[] = [];
      const countryList: Record<string, string | string[]> = countries.countries;
      Object.keys(countryList).forEach((countryCode) => {
        const countryName = countryList[countryCode];
        selectList.push({
          id: countryCode,
          label: Array.isArray(countryName) ? countryName.join(' / ') : countryName,
        });
      });
      return selectList;
    }

    return this.fieldOptions;
  }

  get selectValue (): SelectOption | SelectOption[] | undefined {
    const localValue = this.value;
    if (this.isMultiSelectType && localValue && Array.isArray(localValue)) {
      return this.selectOptions?.filter((selectOption) => localValue.includes(selectOption.id));
    }

    if (!this.isMultiSelectType && localValue && typeof localValue === 'object' && 'id' in localValue) {
      return {
        id: localValue.id,
        label: localValue.label,
      };
    }

    if (localValue) {
      return this.selectOptions?.find((selectOption) => selectOption.id === localValue);
    }

    return undefined;
  }

  created (): void {
    if (this.value) {
      this.elementValue = this.value;

      if (this.isSelectType || this.isCountryType || this.isMultiSelectType) {
        this.elementValue = this.selectValue;
      }
    }
  }
}
